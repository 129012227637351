import Vue from "vue";

// axios.defaults.baseURL = '/proxy_url'
import "amfe-flexible/index";
// elementui引入
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// elementui end
import App from "./App.vue";
//挂载路由
import router from "./router";

import upload from "@/components/upload.vue";

Vue.component("upload", upload);
import VueRouter from "vue-router";
import api from "@/uilts/api.js";
Vue.prototype.$api = api;
import store from "./store";

Vue.prototype.$img = "http://oa.zhhg.org.cn:8080";
// import VueJsonp from 'vue-jsonp'

// Vue.use(VueJsonp)
Vue.use(VueRouter);

Vue.use(ElementUI);
Vue.config.productionTip = false;

// npm install vue-quill-editor --save
// npm install quill --save
//导入富文本编辑器
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);

function hasPermission(value, el = false) {
    if (!Array.isArray(value)) {
        throw new Error(`需要配置权限，列如 v-permission = '['get']'`);
    }
    let permissions = window.sessionStorage.getItem("putispermission");
    try {
        permissions = JSON.parse(permissions);
        permissions = Object.values(permissions);
    } catch (e) {
        return false;
    }
    const hasAuth = value.findIndex((v) => permissions.includes(v)) != -1;

    if (el && !hasAuth) {
        el.parentNode && el.parentNode.removeChild(el);
    }
    return hasAuth;
}
Vue.directive("permission", {
    //el可以取到该节点
    inserted: function (el, bingding) {
        hasPermission(bingding.value, el);
    },
});
Vue.prototype.permission = function (v) {
    let hasAuth = false;
    let permissions = window.sessionStorage.getItem("putispermission");
    try {
        permissions = JSON.parse(permissions);
        permissions = Object.values(permissions);
    } catch (e) {
        return false;
    }
    if (!Array.isArray(v)) {
        hasAuth = permissions.includes(v);
    } else {
        hasAuth =
            v.findIndex((item) => {
                return permissions.includes(item);
            }) != -1;
    }
    return hasAuth;
};

// Error in render: "TypeError: permissions.includes is not a function"

Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        S: this.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
        }
    }
    return fmt;
};
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
