import Vue from "vue";
import VueRouter from "vue-router";
import { Notification } from "element-ui";
import store from "@/store/index.js";
import api from "@/uilts/api.js";
// 1. 创建路由组件
import login from "@/views/login.vue";
// import home from '@/views/home.vue'

Vue.use(VueRouter);

// 处理重复点击当前菜单页，出现警告
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

// 2将路由与组件进行映射
const routes = [
    {
        path: "/",
        name: "login",
        component: login,
    },
];
// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
    // mode: 'history',
    scrollBehavior: () => ({
        x: 0,
        y: 0,
    }),
    routes,
});

router.onError((error) => {
    const pattern = /Loading chunk (\d) + failed/g;
    // const isChunkLoadFailed = error.message.match(pattern);
    const isChunkLoadFailed = pattern.test(error.message);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        // 用路由的repLace方法
        location.reload();
    }
});

router.beforeEach((to, from, next) => {
    if (to.path == "/" && from.path != "/") {
        next();
        router.go(0);
    }
    if (to.path == "/") {
        next();
    } else {
        if (!sessionStorage.getItem("admin")) {
            setTimeout(() => {
                router.push("/");
            }, 100);
            Notification.error({
                title: "请先登录",
            });
        } else if (store.state.routeNav.length == 0) {
            api.getMyselfNav().then((res) => {
                store.commit("getrouteNav", res);
                store.commit("chengroute", {
                    path: to.fullPath,
                    name: to.name,
                });
                setTimeout(() => {
                    router.push({
                        path: to.path,
                        query: to.query,
                    });
                }, 100);
            });
        } else {
            store.commit("chengroute", {
                path: to.fullPath,
                name: to.name,
            });
            next();
        }
    }
});

// 4. 创建和挂载根实例。
export default router;
