<template>
    <div
        class="page"
        :style="{
            background: `url(${require(`@/assets/${BACKGROUND_IMAGE}`)})`,
        }"
    >
        <div class="login-form-container">
            <div class="login-form">
                <div class="login-form-title">{{ APP_NAME }}商户后台</div>
                <div class="login-form-subtitle">开启您的财富之路</div>
                <div class="login-form-group">
                    <label for="username" class="form-label">用户名</label>
                    <input type="text" class="form-control" id="username" name="username" placeholder="请输入用户名" v-model="username" />
                </div>
                <div class="login-form-group">
                    <label for="password" class="form-label">密码</label>
                    <input type="password" class="form-control" id="password" name="password" placeholder="请输入密码" v-model="password" />
                </div>
                <div class="button" @click="login">登&thinsp;录</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { Message, Loading } from "element-ui";

import { BACKGROUND_IMAGE, APP_NAME } from "../uilts/baseInfo";
import api from "@/uilts/api.js";
import { useRouter, useStore } from "@/uilts/vueApi.js";

const store = useStore();
const router = useRouter();

const username = ref("");
const password = ref("");

const login = async () => {
    if (username.value.trim() === "" || password.value.trim() === "") {
        Message.error("用户名或密码不能为空");
        return;
    }

    // 显示加载动画
    const loading = Loading.service({ fullscreen: true });

    // 调用API进行用户登录验证
    try {
        const res = await api.checkLogin({
            phone: username.value,
            pwd: password.value,
        });

        const data = res.data; // 假设API响应有一个名为 `data` 的属性

        if (data && data.status === 1) {
            Message.error("管理员状态不可用");
        } else {
            // window.sessionStorage.setItem("admintoken", data.token);
            window.sessionStorage.setItem(
                "admin",
                JSON.stringify({
                    nickname: data.user_name,
                    ...data,
                })
            );
            window.sessionStorage.setItem("shop_id", data.shop_id);
            window.sessionStorage.setItem("putispermission", JSON.stringify(data.menu_auth_list));
            store.commit("putispermission", data.menu_auth_list);
            router.push("/home");
        }
    } finally {
        loading.close(); // 确保加载动画隐藏
    }

    // 注意: 如果 `throw new Error("LooInterrupt");` 是必须的，那么请保持它，但建议审查这个决定
};
</script>

<style lang="scss" scoped>
.page {
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.login-form-container {
    position: absolute;
    left: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.login-form {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    border: 1px solid rgba(255, 255, 255, 0.2);

    width: 480px;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 50px;
    color: #fff;
    .login-form-subtitle {
        font-size: 16px;
        margin-bottom: 20px;
        // font-weight: bold;
    }
    .login-form-title {
        font-size: 24px;
        font-weight: bold;
    }
    .login-form-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
        label {
            font-size: 14px;
        }
        input {
            width: 100%;
            height: 40px;
            padding: 10px;
            color: #555;
            box-sizing: border-box;
            border-radius: 8px;
            background: #fff;
            box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.5);
            &::placeholder {
                color: #999;
            }
        }
    }
    .button {
        margin-top: 20px;
        width: 100%;
        height: 40px;
        border-radius: 8px;
        background: #ab1b1a;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
        color: #fff;
        cursor: pointer;
        transition: all 0.2s ease;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            background: #991918;
        }
    }
}
</style>
