import fetch from "./request.js";

export default {
    //获取首页展示数据
    getHomeData(data) {
        return fetch.post("/Compensate/getObtainStatistic", data);
    },
    // 获取首页商户数据
    getShopDataHome(data) {
        return fetch.post("/Compensate/getShopData", data);
    },
    // 获取收益图表
    getIncomeChart(data) {
        return fetch.post("/Compensate/getIncomeChart", data);
    },

    //登录
    checkLogin(data) {
        return fetch.post("/Login/checkLogin", data);
    },
    //修改商户端密码
    changepwd(data) {
        return fetch.post("/shop/shopPwdUpdate", data);
    },

    //商户端重新提交商品
    goodsPassUpdate(data) {
        return fetch.post("/goods/goodsPassUpdate", data);
    },

    //获取自己权限列表
    getMyselfNav(data) {
        return fetch.post("/shop/getNowMemberNav", data);
    },

    //获取所有权限列表
    getNowMemberNav(data) {
        return fetch.post("/AdminNav/getMemberNav", data);
    },
    //添加修改权限列表
    addModifyAdminMenu(data) {
        return fetch.post("/AdminNav/addModifyAdminMenu", data);
    },
    //删除权限列表
    delAdminMenu(data) {
        return fetch.post("/AdminNav/delAdminMenu", data);
    },
    //获取管理员角色列表
    getAdminRole(data) {
        // return fetch.post('/common/getRoleInfoList', data)
        return fetch.post("/adminRole/getRoleList", data);
    },
    //添加修改角色列表
    addModifyAdminRole(data) {
        return fetch.post("/adminRole/roleMenuSave", data);
    },
    //修改角色权限列表
    modifyRoleMenu(data) {
        return fetch.post("/adminRole/roleMenuSave", data);
    },
    //获取管理员列表
    getShopAuditorList(data) {
        // return fetch.post('/manage/index', data)
        return fetch.post("/admin/index", data);
    },
    //添加修改管理员
    addModifyAdmin(data) {
        return fetch.post("/admin/adminInfoSave", data);
    },
    //修改管理员密码
    modifyAdminPassword(data) {
        return fetch.post("/admin/adminPassWordUpdate", data);
    },
    //分配审核员
    orderAssign(data) {
        return fetch.post("/Order/orderAssign", data);
    },
    // //商品分类列表
    // getCategoryList(data) {
    // 	return fetch.post('/goods/getCategoryList', data)
    // },
    //商品分类信息
    getGoodsSaveCategoryList(data) {
        return fetch.post("/goods/getGoodsSaveCategoryList", data);
    },
    //商品分类添加修改
    categoryInfoSave(data) {
        return fetch.post("/goods/categoryInfoSave", data);
    },
    //分类删除
    categoryDelete(data) {
        return fetch.post("/goods/categoryDelete", data);
    },

    // 商品列表
    getGoodsList(data) {
        return fetch.post("/goods/getGoodsList", data);
    },
    //商品基本详情添加
    goodsInfoCreate(data) {
        return fetch.post("/goods/goodsInfoCreate", data);
    },
    //商品基本详情修改
    goodsInfoEdit(data) {
        return fetch.post("/goods/goodsInfoEdit", data);
    },
    //商品基本详情
    getGoodsBaseDetails(data) {
        return fetch.post("goods/getGoodsBaseDetails", data);
    },

    //商品颜色信息
    getGoodsDetails(data) {
        return fetch.post("/goods/getGoodsDetails", data);
    },
    //添加商品颜色信息
    goodsDetailsCreate(data) {
        return fetch.post("/goods/goodsDetailsCreate", data);
    },
    //修改商品颜色信息
    goodsDetailsEdit(data) {
        return fetch.post("/goods/goodsDetailsEdit", data);
    },

    //商品内存及价格信息
    getGoodsDetailsSon(data) {
        return fetch.post("/goods/getGoodsDetailsSon", data);
    },
    //添加商品内存及价格信息
    goodsDetailsSonCreate(data) {
        return fetch.post("/goods/goodsDetailsSonCreate", data);
    },
    //修改商品内存及价格信息
    goodsDetailsSonEdit(data) {
        return fetch.post("/goods/goodsDetailsSonEdit", data);
    },

    //文件上传
    uploadFiles(data) {
        return fetch.post("/goods/uploadFiles", data);
    },

    //用户列表
    getMemberList(data) {
        return fetch.post("/member/getMemberList", data);
    },
    //推广系谱
    getMemberXipu(data) {
        return fetch.post("/Member/getMemberXipu", data);
    },
    //获取协议信息
    getAllXieyi(data) {
        return fetch.post("/manage/getAllXieyi", data);
    },
    //修改协议信息
    updateXieyi(data) {
        return fetch.post("/manage/updateXieyi", data);
    },

    //引导词列表
    getAdvertiseList(data) {
        return fetch.post("/common/getAdvertiseList", data);
    },
    //引导词添加修改
    advertiseInfoSave(data) {
        return fetch.post("/common/advertiseInfoSave", data);
    },
    //引导词删除
    advertiseDelete(data) {
        return fetch.post("/common/advertiseDelete", data);
    },
    //系统配置信息
    systemManagement(data) {
        return fetch.post("/Finance/systemManagement", data);
    },
    //修改系统配置信息
    updateSystemManagement(data) {
        return fetch.post("/Finance/updateSystemManagement", data);
    },

    //首页分类列表
    homeCategoryList(data) {
        return fetch.post("/goods/homeCategoryList", data);
    },
    //修改首页分类
    homeCategoryUpdate(data) {
        return fetch.post("/goods/homeCategoryUpdate", data);
    },

    //审核入驻列表
    moderatedShopList(data) {
        return fetch.post("/shop/moderatedShopList", data);
    },
    //入驻审核
    moderatedShop(data) {
        return fetch.post("/shop/moderatedShop", data);
    },
    //店铺列表
    getShopList(data) {
        return fetch.post("/shop/getShopList", data);
    },
    //商品审核列表
    getGoodsPassList(data) {
        return fetch.post("/shop/getGoodsPassList", data);
    },

    //商户提现申请列表
    getShopBalanceLogList(data) {
        return fetch.post("/shop/getShopBalanceLogList", data);
    },
    //商户提现审核
    reviewShopWithdraw(data) {
        return fetch.post("/shop/reviewShopWithdraw", data);
    },
    // 轮播图列表
    lunboList(data) {
        return fetch.post("/Lunbo/lunboList", data);
    },
    // 添加轮播图
    Lunboadd(data) {
        return fetch.post("/Lunbo/add", data);
    },
    //删除轮播图
    Lunbodel(data) {
        return fetch.post("/Lunbo/del", data);
    },
    //修改轮播图状态
    setLunbostatus(data) {
        return fetch.post("/Lunbo/setLunbostatus", data);
    },

    //修改商品上架状态
    baseGoodsStatusUpdate(data) {
        return fetch.post("/goods/baseGoodsStatusUpdate", data);
    },

    //增值服务列表
    getServeList(data) {
        return fetch.post("/goods/getServeList", data);
    },
    //增值服务添加修改
    serveInfoSave(data) {
        return fetch.post("/goods/serveInfoSave", data);
    },
    //增值服务删除
    goodsServerDelete(data) {
        return fetch.post("/goods/goodsServerDelete", data);
    },
    //删除商品
    goodsDelete(data) {
        return fetch.post("/goods/goodsDelete", data);
    },
    //颜色删除
    delGoodsDetails(data) {
        return fetch.post("/goods/delGoodsDetails", data);
    },
    //操作规格删除
    delGoodsDetailsSon(data) {
        return fetch.post("/goods/delGoodsDetailsSon", data);
    },

    //用户提现列表
    memberWithdrawList(data) {
        return fetch.post("/member/memberWithdrawList", data);
    },
    //用户提现审核
    reviewUserWithdraw(data) {
        return fetch.post("/member/reviewUserWithdraw", data);
    },
    //商品审核列表
    getGoodsPassList(data) {
        return fetch.post("/goods/getGoodsPassList", data);
    },
    //商品审核操作
    goodsReview(data) {
        return fetch.post("/goods/goodsReview", data);
    },
    //用户提现列表导出
    userWithdrawExcelExport(data) {
        return fetch.post("/member/userWithdrawExcelExport", data);
    },
    //商户提现列表导出
    shopWithdrawExcelExport(data) {
        return fetch.post("/shop/shopWithdrawExcelExport", data);
    },
    //店铺信息
    getShopInfo(data) {
        return fetch.post("/shop/getShopInfo", data);
    },
    //商家创建/修改店铺信息
    shopInfoSave(data) {
        return fetch.post("/shop/shopInfoSave", data);
    },
    //商家提现流水
    shopBalanceLog(data) {
        return fetch.post("/shop/shopBalanceLog", data);
    },
    //商家申请提现
    shopWithdraw(data) {
        return fetch.post("/shop/shopWithdraw", data);
    },

    // 订单部分
    //天狼星风控
    getTianlanxingInfo(data) {
        return fetch.post("/Airdrop/getTianlanxingInfo", data);
    },
    //订单列表
    getOrderList(data) {
        return fetch.post("/order/getOrderList", data);
    },
    //订单详情
    orderdetail(data) {
        return fetch.post("/order/orderdetail", data);
    },
    //订单统计数
    tongjishu(data) {
        return fetch.post("/order/tongjishu", data);
    },
    //获取快递公司
    kuaidiGongsi(data) {
        return fetch.post("/order/express", data);
    },
    //发货
    orderfahuo(data) {
        return fetch.post("/order/fahuo", data);
    },

    //取消订单
    cancelOrder(data) {
        return fetch.post("/order/cancelOrder", data);
    },
    // 获取省市区
    getSheng(data) {
        return fetch.post("/Order/getSheng", data);
    },
    //修改收获地址
    modifyAddress(data) {
        return fetch.post("/order/modifyAddress", data);
    },
    //加入黑名单
    memberBlack(data) {
        return fetch.post("/Order/memberBlack", data);
    },
    //获取审核资料
    getShenhe(data) {
        return fetch.post("/Order/getShenhe", data);
    },
    //修改实名照片
    xiugaishiming(data) {
        return fetch.post("/Order/xiugaishiming", data);
    },
    //修改买断价
    xiuGaimaiduan(data) {
        return fetch.post("/order/maiduan", data);
    },

    //审核操作
    shenheStatus(data) {
        return fetch.post("/order/shenhe", data);
    },
    //退押金操作
    tuiyajin(data) {
        return fetch.post("/order/tuiyajin", data);
    },

    //续租列表
    xuzulist(data) {
        return fetch.post("/order/xuzulist", data);
    },
    //审核续租列表
    shenhexuzu(data) {
        return fetch.post("/order/shenhexuzu", data);
    },

    //买断申请列表
    maiduanlist(data) {
        return fetch.post("/order/maiduanlist", data);
    },

    //买断申请列表审核
    shenhemaiduan(data) {
        return fetch.post("/order/shenhemaiduan", data);
    },
    //线下支付代扣
    xianxiaDaikou(data) {
        return fetch.post("/order/xianxia", data);
    },
    // 线下退款
    refundOffline(data) {
        return fetch.post("/order/refundOffline", data);
    },

    // 修改账单日期
    editDate(data) {
        return fetch.post("/order/editBill", data);
    },

    // 修改账单金额
    editAmount(data) {
        return fetch.post("/order/editBill", data);
    },

    //查看物流
    lookPost(data) {
        return fetch.post("/Order/lookPost", data);
    },
    //修改押金
    edityj(data) {
        return fetch.post("/order/edityj ", data);
    },

    //自提确认收货
    writeOffWithdrawalCode(data) {
        return fetch.post("/Compensate/writeOffWithdrawalCode", data);
    },
    //查看归还物流
    lookBackPost(data) {
        return fetch.post("/Order/lookBackPost", data);
    },
    //归还验收
    yanshou(data) {
        return fetch.post("/order/yanshou", data);
    },
    // 征信报告
    getCreditReport(data) {
        return fetch.post("/order/getCreditReport", data);
    },

    // 查询征信报告
    queryCreditReport(data) {
        return fetch.post("/order/queryCreditReport", data);
    },

    // 风控报告
    getRiskReport(data) {
        return fetch.post("/order/getShenhe", data);
    },

    // 同步风控报告
    syncRiskReport(data) {
        return fetch.post("/order/syncRiskReport", data);
    },
    //、、、、、、、、、、、

    /***************************************************************************************/

    // //获取管理员日志
    // getAdminLog(data) {
    // 	return fetch.post('/admin/AdminLog/getAdminLog', data)
    // },

    // //修改管理员状态
    // modifyAdminRoleStatus(data) {
    // 	return fetch.post('/admin/AdminRole/modifyAdminRoleStatus', data)
    // },
    //删除角色列表
    delAdminRole(data) {
        return fetch.post("/admin/AdminRole/delAdminRole", data);
    },
    //删除角色某个权限
    delRoleMenu(data) {
        return fetch.post("/admin/AdminRole/delRoleMenu", data);
    },
    //删除管理员
    delAdmin(data) {
        return fetch.post("/admin/Admin/delAdmin", data);
    },
    // 上传公证信息
    uploadNotarInfo(data) {
        return fetch.post("/order/uploadNotarInfo", data);
    },
    // 公证信息审核
    notaryReady(data) {
        return fetch.post("/order/notaryReady", data);
    },
    // 获取公证信息
    getNotaryLink(data) {
        return fetch.post("/order/getNotaryLink", data);
    },
    // 合并账单 /shopproj/order/mergeBill
    mergeBill(data) {
        return fetch.post("/order/mergeBill", data);
    },
    // 更新商家备注：order/updateMerRemark
    updateMerRemark(data) {
        return fetch.post("/order/updateMerRemark", data);
    },
    // 更新联系人：order/updateContacts
    updateContacts(data) {
        return fetch.post("/order/updateContacts", data);
    },
    // 更新设备号：order/updateDeviceNo
    updateDeviceNo(data) {
        return fetch.post("/order/updateDeviceNo", data);
    },
    // 获取消费记录：shopproj/order/getSpendingBill
    getSpendingBill(data) {
        return fetch.get(
            `/ShopServiceSpeed/getSpeedRecord?${Object.keys(data)
                .map((key) => key + "=" + data[key])
                .join("&")}`
        );
    },
    // 消费钱包充值：ShopServiceSpeed/recharge
    walletRecharge(data) {
        return fetch.post("/ShopServiceSpeed/recharge", data);
    },
    // 上报征信 leasingContractApply
    leasingContractApply(data) {
        return fetch.post("/order/leasingContractApply", data);
    },
    // 结清上报 leasingSettleData
    leasingSettleData(data) {
        return fetch.post("/order/leasingSettleData ", data);
    },
    // 结清上报确认 leasingSettleConfirm
    leasingSettleConfirm(data) {
        return fetch.post("/order/leasingSettleConfirm", data);
    },
    // 追偿结清 leasingDunSettle
    leasingDunSettle(data) {
        return fetch.post("/order/leasingDunSettle", data);
    },
    // 租金账单 /admproj/order/getBillList?order_id=1
    getBillList(data) {
        return fetch.get(
            `/order/getBillList?${Object.keys(data)
                .map((key) => key + "=" + data[key])
                .join("&")}`
        );
    },
    // 获取芝麻信用类目 ZhiMaCategory/getCategoryList
    getZhiMaCategoryList(data) {
        return fetch.get(
            `/ZhiMaCategory/getCategoryList?${Object.keys(data)
                .map((key) => key + "=" + data[key])
                .join("&")}`
        );
    },
};
