<template>
    <div>
        <el-upload
            v-if="isDuotu == false"
            class="avatar-uploader"
            :action="BASE_URL + '/shopproj/goods/uploadFiles'"
            :data="shangchuanData"
            name="file"
            :show-file-list="false"
            v-loading="loading"
            :style="{ width, height }"
            :on-success="handleAvatarSuccess"
            :class="{ round: round }"
            :id="idkey"
            :before-upload="beforeUpload"
            multiple
            :on-error="err"
            accept="image/*"
            :key="idkey"
        >
            <div class="el-upload-list--picture-card" v-if="imgUrl">
                <img :src="imgUrl" class="avatar el-upload-list__item-thumbnail" :style="{ width, height }" />
                <span class="el-upload-list__item-actions" :style="{ width, height }" @click.stop>
                    <span class="el-upload-list__item-preview" @click.stop="handlePictureCardPreview(imgUrl)">
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-preview" v-if="!dis">
                        <i class="el-icon-upload2" @click.stop="upload"></i>
                    </span>
                </span>
            </div>
            <i v-if="!imgUrl" class="el-icon-plus avatar-uploader-icon" :style="{ width, height, 'line-height': height }"></i>
        </el-upload>

        <!-- 多图 -->
        <el-upload
            :action="BASE_URL + '/shopproj/goods/uploadFiles'"
            :data="shangchuanData"
            name="file"
            list-type="picture-card"
            v-if="isDuotu == true"
            :on-success="handlePic"
            multiple
            :file-list="imgUrl"
            :on-remove="handleRemove"
        >
            <i class="el-icon-plus" />
        </el-upload>

        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>
<script>
import { BASE_URL } from "@/uilts/baseInfo";

export default {
    name: "upload",
    props: {
        imgUrl: {
            type: [Array, String],
            default: () => "",
        },
        width: {
            type: String,
            default: () => "120px",
        },
        height: {
            type: String,
            default: () => "120px",
        },
        round: {
            type: Boolean,
            default: () => false,
        },
        dis: {
            type: Boolean,
            default: () => false,
        },
        idkey: {
            type: String,
            default: () => "avatar-uploader",
        },
        isDuotu: {
            type: Boolean,
            default: () => false,
        },
        path: {
            type: String,
            default: () => "",
        },
    },
    watch: {
        imgUrl(newValue, oldValue) {
            if (this.isDuotu) {
                let xinxi = [];
                for (let i in newValue) {
                    xinxi.push({ url: newValue[i] });
                }
                this.imgUrl = xinxi;
            }
        },
    },
    data() {
        return {
            BASE_URL,
            dialogImageUrl: "",
            dialogVisible: false,
            loading: false,
            shangchuanData: {
                token: "",
                path: "",
            },
            disabled: false,
            pictureLists: [],
        };
    },
    created() {
        let xinxi = sessionStorage.getItem("admin") ? JSON.parse(sessionStorage.getItem("admin")) : "";
        this.shangchuanData.token = xinxi ? xinxi.token : "";
        this.shangchuanData.path = this.path;
    },
    methods: {
        //删除
        handleRemove(file) {
            const url = file.response.data.url;
            const arr = [];
            this.pictureLists.forEach((element) => {
                if (element !== url) {
                    arr.push(element);
                }
            });
            this.pictureLists = arr;
            this.$emit("getImg", this.pictureLists);
        },
        // 上传多图上传成功
        handlePic(res, file) {
            const that = this;
            this.loading = false;
            if (!res) {
                return;
            }

            if (res.status == 1) {
                this.$message.success("上传图片成功");
                that.pictureLists.push(res.data.path);
                this.$emit("getImg", this.pictureLists);
            } else {
                this.$message.error(res.info);
            }
        },
        //单图上传成功
        handleAvatarSuccess(res, file) {
            this.loading = false;
            if (!res) {
                return;
            }

            if (res.status == 1) {
                this.$message.success("上传图片成功");
                this.$emit("getImg", res.data.path);
            } else {
                this.$message.error(res.info);
            }
        },
        beforeUpload(file) {
            // 显示loading动画
            const isLt4M = file.size / 1024 / 1024 < 4;
            if (!isLt4M) {
                this.$message.error("上传图片大小不能超过 4MB!");
            }
            if (!isLt4M) {
                return isLt4M;
            } else {
                this.loading = true;
            }
        },
        handlePictureCardPreview(imgUrl) {
            this.dialogImageUrl = imgUrl;
            this.dialogVisible = true;
        },
        upload() {
            document.querySelector("#" + this.idkey + " input").click();
        },
        err(e) {
            this.loading = false;
        },
    },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.round {
    border-radius: 50%;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    border: 1px dashed #d9d9d9;
}

.avatar {
    width: 150px;
    height: 150px;
    display: block;
    object-fit: cover;
}
</style>
