<template>
    <div class="container">
        <div class="sidebar">
            <div class="navigation-level-1" v-for="item in routeNav" :key="item.id">
                <div class="title" :class="{ isActivated: item.isActivated }" @click="handleClick(item)">
                    <span>
                        <i class="el-icon-arrow-right" :class="{ rotated: !item.isCollapsed }" v-if="item.children.length > 0"></i>
                        <i class="el-icon-s-home" v-else-if="item.path == '/home'"></i>
                    </span>
                    <span>{{ item.name }}</span>
                </div>
                <div class="collapse" v-if="item.children.length > 0" :id="item.id" :style="item.isCollapsed ? 'max-height: 0px' : 'max-height: ' + (item.children.length * 36 + 4) + 'px'">
                    <div class="navigation-level-2" v-for="ite in item.children" :key="ite.id">
                        <div class="title" :class="{ isActivated: ite.isActivated }" :id="ite.id" @click="handleClick(ite)">{{ ite.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore, useRouter } from "@/uilts/vueApi.js";

const store = useStore();
const router = useRouter();

const routeNav = ref([]);

const updataNav = () => {
    const currentPath = document.URL.split("#").pop();
    routeNav.value = [];
    store.state.routeNav.forEach((item) => {
        routeNav.value.push({
            ...item,
            isActivated: currentPath === item.path,
            isCollapsed: item.children.find((ite) => currentPath === ite.path) ? false : true,
            children: item.children.map((ite) => {
                return {
                    ...ite,
                    isActivated: currentPath === ite.path,
                };
            }),
        });
    });
};
updataNav();

const handleClick = async (item) => {
    if (item.children && item.children.length > 0) {
        routeNav.value = routeNav.value.map((ite) => {
            if (ite.id === item.id) {
                ite.isCollapsed = !ite.isCollapsed;
            }
            return ite;
        });
    } else {
        await router.push(item.path);
        updataNav();
    }
};
</script>

<style scoped>
.container {
    background: #212529;
    min-height: 100vh;
}
.sidebar {
    position: sticky;
    top: 0;
    width: 200px;
    color: #fff;
    padding: 16px 4px;
    .navigation-level-1 {
        .title {
            padding: 8px 16px;
            cursor: pointer;
            width: fit-content;
            border-radius: 6px;
            transition: all 0.3s ease;
            &.isActivated,
            &:hover {
                box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.5);
                background: #111;
            }
            &.hidden {
                color: transparent;
            }
        }
        > .title {
            margin: 8px 4px;
            font-size: 16px;
            font-weight: bold;
            display: grid;
            grid-template-columns: 16px 1fr;
            gap: 8px;
            align-items: center;
        }
        .collapse {
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            .navigation-level-2 {
                > .title {
                    margin: 4px;
                    margin-left: 30px;
                    font-size: 12px;
                }
            }
        }
    }
}
.el-icon-arrow-right {
    transition: all 0.3s ease-in-out;
    &.rotated {
        transform: rotate(90deg);
    }
}
</style>
