import axios from "axios";
import { Message, Notification } from "element-ui";
import router from "../router/index.js";
import { BASE_URL } from "./baseInfo.js";
const http = axios.create({
    //通用请求头的地址前缀

    //本地
    // baseURL: 'http://lefenbei.com/',
    //测试服务器
    // baseURL: 'http://43.136.19.253/shopproj/',
    baseURL: BASE_URL + "/shopproj/",
    timeout: 100000, //超时时
});

// 添加请求拦截器
http.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么

        let config1 = config;
        config1.headers = {
            "Content-Type": "application/json",
        };

        let xinxi = sessionStorage.getItem("admin") ? JSON.parse(sessionStorage.getItem("admin")) : "";
        let token = xinxi ? xinxi.token : "";

        let shop_id = sessionStorage.getItem("shop_id");
        if (config1.data) {
            config1.data.token = token;
            config1.data.shop_id = shop_id;
        } else if (config1.params) {
            config1.params.token = token;
            config1.params.shop_id = shop_id;
        } else {
            config1.params = {
                token,
                shop_id,
            };
        }
        if (config.url.indexOf("userWithdrawExcelExport") > -1 || config.url.indexOf("shopWithdrawExcelExport") > -1) {
            config1.responseType = "blob";
            config1.fileName = "导出文件";
        }
        // Trim all string values in the data
        function trimStringProperties(obj) {
            for (let key in obj) {
                if (typeof obj[key] === "string") {
                    obj[key] = obj[key].trim();
                } else if (typeof obj[key] === "object") {
                    trimStringProperties(obj[key]);
                }
            }
        }
        if (config1.data) {
            trimStringProperties(config1.data);
        }
        return config1;
    },
    function (error) {
        // 对请求错误做些什么
        console.error(error); // 添加这一行
        if (error.response) {
            Notification.error({
                title: "错误",
                type: "error",
                message: error.response.data.message,
            });
        } else {
            Notification.error({
                title: "错误",
                type: "error",
                message: "网络异常，请稍后重试",
            });
        }
        return "";
    }
);

// 添加响应拦截器
http.interceptors.response.use(
    (response) => {
        // 对响应数据做点什么
        if (response.request.responseURL.indexOf("userWithdrawExcelExport") > -1 || response.request.responseURL.indexOf("shopWithdrawExcelExport") > -1) {
            if (response.data) {
                let blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                }); // 为blob设置文件类型，这里以.xlsx为例
                let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
                let a = document.createElement("a");
                a.href = url;
                a.download = response.config.fileName || "导出文件";
                a.click();
            }
            return "导出成功";
        }
        if (response.data) {
            if (response.data.status == 1) {
                return response.data;
            } else if (response.data.status == -998 || response.data.status == -999) {
                Notification.error({
                    title: "错误",
                    type: "error",
                    message: "登录过期，请重新登录",
                });
                router.replace("/");
            } else {
                Notification.error({
                    title: "错误",
                    type: "error",
                    message: response.data.info || "请求失败，请稍后重试",
                });
            }
            return {};
        }
    },
    function (error) {
        if (error.response) {
            Notification.error({
                title: "错误",
                type: "error",
                message: error.response.data.message,
            });
        } else {
            Notification.error({
                title: "错误",
                type: "error",
                message: "网络异常，请稍后重试",
            });
        }
        return {};
    }
);

export default http;
